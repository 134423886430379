.gradient-border-animation {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.gradient-border-animation:before {
  content: "";
  background: linear-gradient(
    45deg,
    #f2a5a5,
    /* Soft coral */ #f2c4a5,
    /* Light apricot */ #f2e4a5,
    /* Light goldenrod */ #b3f2a5,
    /* Light green */ #a5f2e0,
    /* Light mint */ #a5c6f2,
    /* Light periwinkle */ #c8a5f2,
    /* Light lavender */ #f2a5b7,
    /* Soft rose */ #f2a5a5 /* Soft coral (back to start) */
  );

  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-border-animation 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-border-animation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
