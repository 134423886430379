.gradient-border-animation2 {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.gradient-border-animation2:before {
  content: "";
  background: linear-gradient(
    45deg,
    #800000,
    #803900,
    #808000,
    #287a00,
    #007d71,
    #002063,
    #4e007e,
    #7e0064,
    #800000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-border-animation 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 50%;
}

@keyframes glowing-border-animation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}
